<template>
  <div class="rightLink">
    <div class="bottomDiv" style="height: 100%">
      <div class="title">用户统计分析</div>
      <div class="statistics">
        <div>
          <p class="s_title">用户总数</p>
          <p class="s_water">{{ usersnum.user_len }}</p>
          <!-- <p class="s_profress">+0.1%</p> -->
          <!-- <el-progress :percentage="50" :show-text="false" /> -->
        </div>
        <div>
          <p class="s_title p_bottom">本月新增用户数</p>
          <p class="s_water">{{ usersnum.new_user }}</p>
          <p class="s_profress"></p>
          <!-- <el-progress :percentage="50" :show-text="false" /> -->
        </div>
        <div>
          <p class="s_title">正常用户</p>
          <p class="s_water">{{ usersnum.user_len }}</p>
          <!-- <p class="s_profress">+0.1%</p> -->
          <!-- <el-progress :percentage="50" :show-text="false" /> -->
        </div>
        <div>
          <router-link to="/user_manage/user_online">
          <p class="s_title" style="color: #6e39e8">在线用户</p>
          <p class="s_water" style="color: #6e39e8">{{ usersnum.online_user }}</p>
          </router-link>
          <!-- <p class="s_profress">+0.1%</p> -->
          <!-- <el-progress :percentage="50" :show-text="false" /> -->
        </div>
        <!-- <div>
          <p class="s_title">充电中用户</p>
          <p class="s_water">{{ usersnum.charging_user }}</p> -->
        <!-- </div> -->
      </div>
    </div>

    <div class="bottomDiv" style="margin-top: 20px; height: 100%">
      <div class="title">用户查询</div>
      <div class="formDiv">
        <el-form label-position="left" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="选择省份:">
                <el-select
                  style="width: 100%"
                  v-model="FormData.province"
                  placeholder="请选择省"
                  @change="handleProvinceChange"
                  clearable
                >
                  <el-option
                    v-for="provinces in provinceList"
                    :key="provinces.value"
                    :value="provinces.label"
                    :label="provinces.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="选择市:">
                <el-select
                  style="width: 100%"
                  v-model="FormData.city"
                  placeholder="请选择市"
                  @change="handleCityChange"
                  clearable
                >
                  <el-option
                    v-for="citys in cityList[0]"
                    :key="citys.value"
                    :value="citys.label"
                    >{{ citys.label }}</el-option
                  >
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="选择区/县:">
                <el-select
                  style="width: 100%"
                  v-model="FormData.county"
                  placeholder="请选择区/县"
                  clearable
                >
                  <el-option
                    v-for="countys in countyList[0]"
                    :key="countys.value"
                    :value="countys.label"
                    >{{ countys.label }}</el-option
                  >
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="用户编号:">
                <el-input
                  v-model="FormData.user_number"
                  placeholder="请输入用户编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入用户手机号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col v-show="isadmin" :span="7">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="400px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="用户编号" prop="user_number" />
          <el-table-column label="用户手机号" prop="phone" />
          <!-- <el-table-column label="用户状态" prop="is_active">
            <template #default="scope">
              <span v-if="scope.row.is_active === true">启用</span>
              <span v-if="scope.row.is_active === false">暂停</span>
            </template>
          </el-table-column>> -->
          <el-table-column label="所在省" prop="province" />
          <el-table-column label="所在市" prop="city" />
          <el-table-column label="所在区县" prop="county" />
          <!-- <el-table-column label="电动车余额(元)" prop="balance" :formatter="rounding" />
          <el-table-column label="电动汽车余额(元)" prop="balance_new" :formatter="rounding"/> -->
          <el-table-column
            label="注册时间"
            prop="opening_time"
            :formatter="formatDate"
          />

          <!-- <el-table-column label="所属运营商" prop="username" /> -->
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :small="small"
            layout="total,slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
      </div>
    </div>

    <div class="bottomDiv" style="height: 18vh; margin-top: 20px">
      <div class="title">用户管理快捷方式</div>
      <div class="statistics1">
        <div>
          <router-link to="/user_manage/user_edit">
            <img src="../../assets/img/icon_1.png" alt="" />
            <p>用户数据编辑</p>
          </router-link>
        </div>
        <div>
          <router-link to="/order_manage/order_history">
            <img src="../../assets/img/icon_5.png" alt="" />
            <p>用户消费记录</p>
          </router-link>
        </div>
        <div>
          <router-link to="/revenue_manage/invest_detail">
            <img src="../../assets/img/icon_2.png" alt="" />
            <p>用户充值记录</p>
          </router-link>
        </div>
        <!-- <div>
            <router-link to="/user_manage/recharge_record">
              <img src="../../assets/img/icon_3.png" alt="" />
              <p>卡充值记录</p>
            </router-link>
          </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_user, operator_data, user_statistics } from "@/request/api";
import { ElMessage } from "element-plus";
import { regionData } from "element-china-area-data";

// import ProvinceCityCountry from '../../assets/json/address'

export default {
  name: "SearchMan",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {},
      isadmin: false,
      users: [],
      usersnum: {},
      tableData: [],
      loading: false,

      options: regionData,

      // 省市县
      regionData,
      provinceList: [],
      cityList: [],
      countyList: [],
      province: "",
      city: "",
      county: "",

      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },

      Dialog1: {
        editLevy: false,
        DialogData: {},
      },

      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
    });
    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };

    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let now = new Date(data);
      let year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      let minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
      let seconds =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); //得到秒数

      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        seconds
      );
    };

    const initProvinceList = () => {
      // 初始化省份列表数据源
      regionData.forEach((tab) => {
        data.provinceList.push(tab);
      });
      console.log(data.provinceList, "--**99");
    };
    const handleProvinceChange = (val) => {
      console.log(val, "009-");
      data.cityList = [];
      data.countyList = [];
      data.province = val;
      data.FormData.city = "";
      data.FormData.county = "";
      console.log(data.province, "]]");
      initCityList();
    };

    const initCityList = () => {
      console.log(data.province, "--2");
      // 初始化城市列表数据源
      data.provinceList.forEach((tab1) => {
        // console.log(tab1,'--==tab1');
        if (tab1.label == data.province) {
          data.cityList.push(tab1.children);
        }
      });
      console.log(data.cityList, "pppppp");
    };

    const handleCityChange = (value1) => {
      data.countyList = [];
      data.city = value1;
      data.FormData.county = "";
      console.log(data.city, "]]999");
      initCountyList();
    };

    const initCountyList = () => {
      // 初始化县/区列表数据源
      console.log(data.city, "--299");
      // 初始化城市列表数据源
      data.cityList[0].forEach((tab2) => {
        console.log(tab2, "tab2");
        // tab2.forEach((tab3)=>{
        //   console.log(tab3,'tab3');
        //    if(tab3.label==this.selectedCity){
        //  this.countyList.push(tab3.children)
        // }
        // })
        if (tab2.label == data.city) {
          data.countyList.push(tab2.children);
        }
      });
      console.log(data.countyList, "pppppp999");
    };

    const getList = () => {
      const dataa = {
        operator_id: sessionStorage.getItem("userID"),
        username: data.FormData.username,
        province: data.FormData.province,
        city: data.FormData.city,
        county: data.FormData.county,
        user_number: data.FormData.user_number,
        phone: data.FormData.phone,
        page: data.currentPage,
      };

      data.loading = true;
      sel_user(dataa).then((res) => {
        console.log(res, "用户列表111");
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.count_data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };

    const getUsernum = () => {
      const dataa = {
        operator_id: sessionStorage.getItem("userID"),
      };
      user_statistics(dataa).then((res) => {
        console.log(res, "用户数据列表");
        data.usersnum.user_len = res.user_len;
        data.usersnum.new_user = res.new_user;
        data.usersnum.online_user = res.online_user;
        //   res.forEach((a)=>{
        //     users1.push(a.username)
        // })
        // if(users1.indexOf(username1)==-1){
        //   data.isadmin = true
        // }else{
        //   data.isadmin = false
        //   data.FormData.username = username1
        // }
      });
    };
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
      //
    };
    onMounted(() => {
      console.log(data);
      getList();
      initProvinceList();
      getUsername();
      getUsernum();
    });
    return {
      ...toRefs(data),
      searchBtn,
      rounding,
      getList,
      handleSizeChange,
      handleCurrentChange,
      initProvinceList,
      handleProvinceChange,
      handleCityChange,
      initCityList,
      initCountyList,
      getUsername,
      getUsernum,
      formatDate,
    };
  },
};
</script>

<style>
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  min-height: 180px;
}
.statistics {
  display: flex;
  width: 90%;
  padding-top: 15px;
  /* background: red; */
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 > div > a {
  text-decoration: none;
  color: black;
}
.statistics .s_title {
  font-size: 16px;
  text-align: center;
}
.router-link-active {  
  color: inherit !important;
}  

.p_bottom .el-icon {
  text-align: center;
}
.s_water {
  line-height: 40px;
  font-size: 19px;
  text-align: center;
  /* font-weight: bold; */
}
.s_profress {
  line-height: 25px;
}

.statistics > div {
  width: 16%;
  padding: 10px 20px;
  text-align: center;

  text-align: center;
  line-height: 35px;
  margin-bottom: 8px;
}
.statistics > div:nth-child(1) {
  background-image: url("../../assets/img/six.png");
  color: #ff6060;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.statistics > div:nth-child(2) {
  background-image: url("../../assets/img/four.png");
  color: #2ed2aa;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.statistics > div:nth-child(3) {
  background-image: url("../../assets/img/five.png");
  color: #29a1ec;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.statistics > div:nth-child(4) {
  background-image: url("../../assets/img/three.png");
  color: #6e39e8;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.statistics > div:nth-child(5) {
  background-image: url("../../assets/img/two.png");
  color: #94bb22;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.statistics > div:nth-child(6) {
  background-image: url("../../assets/img/one.png");
  color: #ffa300;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.statistics > div > .el-progress {
  width: 45%;
}
.el-progress /deep/ .el-progress-bar__outer {
  --el-border-color-lighter: #fff;
}
.statistics1 > div {
  width: 13%;
  margin: 10px auto;
  /* border: 1px solid red; */
  padding: 10px;
  /* height: 200px; */
}

</style>







